import { useRouter } from 'apis/history';
import * as React from 'react';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { Text } from 'components';
import styled from 'styled-components';
import { Quiz } from 'types/quiz';

interface FooterProps {
  disclaimerText: string[];
}

const LCFooter: React.FC<FooterProps> = ({ disclaimerText }) => {
  const isBrowser = typeof window !== 'undefined';

  const { goToPrivacy, goToTerms, goToContact, goToReviews, goToFAQ } =
    useRouter();
  const { isTablet } = useQuery();
  const quiz = isBrowser
    ? new URLSearchParams(window.location.search).get('qz') ?? Quiz.Main
    : '';

  return (
    <footer>
      <Container>
        <Wrapper>
          <Links>
            {/* <LinkWrap onClick={() => goToPrivacy()}>
              <StyledTextT type="bodyM600" color="light0">
                Privacy Policy
              </StyledTextT>
            </LinkWrap>
            <LinkWrap onClick={() => goToTerms()}>
              <StyledTextT type="bodyM600" color="light0">
                Terms & Conditions
              </StyledTextT>
            </LinkWrap> */}
            {quiz === Quiz.Main ? (
              <>
                <LinkWrap onClick={() => goToReviews()}>
                  <StyledTextT type="bodyM600" color="light0">
                    Reviews
                  </StyledTextT>
                </LinkWrap>
                {/* <LinkWrap>
                  <StyledTextT type="bodyM600" color="light0">
                    <StyledLink href="https://help.nova.health/hc/en-us">
                      Help Center
                    </StyledLink>
                  </StyledTextT>
                </LinkWrap> */}
                <LinkWrap onClick={() => goToContact()}>
                  <StyledTextT type="bodyM600" color="light0">
                    Contact Us
                  </StyledTextT>
                </LinkWrap>
              </>
            ) : null}
          </Links>

          <DisclaimerContainer>
            <DisclaimerWrapper>
              <DisclaimerWrap onClick={() => goToPrivacy()}>
                <Text type="bodyS600" color="light0">
                  Privacy Policy
                </Text>
              </DisclaimerWrap>
              <Dot>&#183;</Dot>
              <DisclaimerWrap onClick={() => goToTerms()}>
                <Text type="bodyS600" color="light0">
                  Terms & Conditions
                </Text>
              </DisclaimerWrap>
            </DisclaimerWrapper>
            <Wrap>
              {disclaimerText.map((paragraph, index) => (
                <StyledText
                  key={index}
                  type={isTablet ? 'bodyXM400' : 'bodyXM400'}
                  color="light1"
                >
                  {paragraph}
                </StyledText>
              ))}
            </Wrap>
          </DisclaimerContainer>
        </Wrapper>
      </Container>
    </footer>
  );
};

export default LCFooter;

const StyledLink = styled.a`
  &:hover {
    opacity: 1 !important;
  }
`;

const Wrap = styled.div`
  gap: 0.25rem;
`;

const Dot = styled.div`
  color: #fff;
`;

const DisclaimerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const DisclaimerWrap = styled.div`
  cursor: pointer;
`;

const StyledTextT = styled(Text)`
  font-family: Mulish;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1640px;
  margin: auto;
  gap: 2rem;
  @media ${tablet} {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;

const StyledText = styled(Text)`
  // width: 400px;
`;

const DisclaimerContainer = styled.div`
  width: 42rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  @media ${tablet} {
    align-items: center;
    width: unset;
    gap: 0.5rem;
  }
`;

const Container = styled.div`
  display: flex;
  padding: 2rem 3rem;
  gap: 1.5rem;
  background: ${({ theme }) => theme.colors.green110};
  // height: 104px;
  @media ${tablet} {
    padding: unset;
    height: unset;
    text-align: center;
    padding: 1rem 3rem;
  }
`;

const Links = styled.div`
  color: #fff;
  display: flex;
  justify-content: center;
  gap: 2.25rem;
  flex-wrap: wrap;
  @media ${mobile} {
    gap: unset;
  }
`;

const LinkWrap = styled.div`
  cursor: pointer;
  padding: 0 12px;
  white-space: nowrap;
  @media ${tablet} {
    margin-bottom: 0.5rem;
  }
`;
